<template>
  <div class="flex justify-center">
    <div class="sm:w-max-3xl w-full">
      <vs-table class="w-auto relative" ref="table">
        <template #notFound> You don't have any transactions yet. </template>
        <template #thead class="w-full">
          <vs-tr>
            <vs-th class="!w-1/6"> Type</vs-th>
            <vs-th class="!w-1/3"> Id</vs-th>
            <vs-th> User</vs-th>
            <vs-th class="!w-1/6"> Amount</vs-th>
            <vs-th> Date </vs-th>
          </vs-tr>
        </template>
        <template class="w-full" #tbody v-if="transactions">
          <vs-tr :key="i" v-for="(tr, i) in transactions.docs" :data="tr">
            <vs-td> {{ getTransactionType(tr) }} </vs-td>
            <vs-td> {{ getTransactionId(tr) }} </vs-td>
            <vs-td> {{ tr.user.username }} </vs-td>
            <vs-td> {{ tr.amount }} $</vs-td>
            <vs-td> {{ tr.createdAt | formatTimestamp }}</vs-td>
          </vs-tr>
        </template>
        <template #footer v-if="transactions">
          <vs-pagination v-model="page" :length="transactions.totalPages" />
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import { getTransactions } from "@/services/ApiService";

export default {
  name: "Transactions",
  data: () => ({
    page: 1,
    max: 10,
    transactions: [],
    transactionTypes: ["wallet", "subscription"],
  }),
  methods: {
    async getTransactions() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      this.transactions = await getTransactions(this.page)
        .then((response) => response)
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
          loading.close();
        });
      loading.close();
    },
    getTransactionId(transaction) {
      const type = this.getTransactionType(transaction);

      const obj = {
        wallet: transaction.wallet,
        subscription: transaction.subscription,
      };

      return obj[type];
    },
    getTransactionType(transaction) {
      return this.transactionTypes[transaction.type];
    },
  },
  async mounted() {
    await this.getTransactions();
  },
  watch: {
    page() {
      this.getTransactions();
    },
  },
};
</script>
